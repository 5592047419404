.dnz-login-background{
    background-image: url('../../../assets/images/denizbank-login-background.png');
    height: 100%;
    background-size: cover;
}
.dnz-logo{
    background-size: cover;
    width: 289.4px;
    height: 82px;
    margin: 0 42.6px 20px 22px;
    object-fit: contain;
}
.intertech-logo{
    margin-bottom: 10px;
}
.dnz-login-btn {
    width: 100%;
    margin-top: 25px;
}
.dnz-login-btn button{
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.dnz-login-btn button:hover{
    color: #ffffff; 
    background-color: #9433a4 !important;
    border-color: #285e8e;
}